import React, { useState } from 'react';
import loadable from '@loadable/component';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from "history";
import './App.css';
const CompletedPage = loadable(() => import('./components/Report/CompletedPage'));
const SurveyPage = loadable(() => import('./components/Survey/Survey'));
const Home = loadable(() => import('./components/Home/Home'));



const history = createBrowserHistory();

const clientData = {
    "client": "offstandards GmbH",
    "selectedParameters": [
        "Proaktivität",
        "Selbstbewusstsein",
        "Kundenorientierung"
    ],
    "CompanyShwartzScore": 60,
    "companyClasses": {
        "A": 6,
        "A1": 6,
        "A2": 6,
        "A3": 6,
        "A4": 6,
        "A5": 6,
        "C": 6,
        "C1": 6,
        "C2": 6,
        "C3": 6,
        "C4": 6,
        "C5": 6,
        "E": 6,
        "E1": 6,
        "E2": 6,
        "E3": 6,
        "E5": 6,
        "E6": 6,
        "N": 6,
        "N1": 6,
        "N2": 6,
        "N3": 6,
        "N4": 6,
        "N6": 6,
        "O": 6,
        "O1": 6,
        "O2": 6,
        "O3": 6,
        "O4": 6,
        "O5": 6
    }
};

export default function App () {
    const [completed, setCompleted] = useState(false);
    const [surveyResult, setSurveyResult] = useState('');
    const primaryColor = "#EF4E37";
    const secondaryColor = "#000000";
    return (
        <Router history={history}>
            <Switch>

                <Route path="/fitcheck" >
                    <SurveyPage completed={completed} setSurveyResult={setSurveyResult} setCompleted={setCompleted} primaryColor={primaryColor} />
                </Route>

                <Route path="/report" >
                    <CompletedPage surveyResult={surveyResult} clientData={clientData} primaryColor={primaryColor} secondaryColor={secondaryColor} />
                </Route>

                <Route exact path="/" >
                    <Home />
                </Route>

            </Switch>
        </Router>
    );
}


